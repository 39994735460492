/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {useMatch} from 'react-router-dom';
import {
  Rect,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryVoronoiContainer,
} from 'victory';
import {BarMetric, FINETUNED, PerformerName} from '../../definitions/metrics';
import {PageRoutes} from '../../definitions/routes';
import {chartTheme} from '../../styles/chartThemeGroup';
import {theme} from '../../styles/emotionTheme';
import {performerTheme} from '../../styles/performerTheme';
import {useDataContext} from '../../context/DataContext';
import {CameraOutlined} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';
import {exportComponentAsPNG} from 'react-component-export-image';
import React, {useRef} from 'react';

export interface VictoryRow {
  x: string;
  y: number;
  y0: number;
  x0: number;
  label?: string;
  fill: string;
  pattern: string | null;
}

export interface BarChartProps {
  data: BarMetric[];
  showAllModels: boolean;
  teamNames: Record<string, string>;
}

export const BarChartGroup: React.FC<BarChartProps> = ({data, showAllModels, teamNames}) => {
  const [, {getSystemColor, getPatternColor, getReverseMetric}] = useDataContext();
  const match = useMatch({path: PageRoutes.PERFORMEROVERVIEW});
  const isOverview = match !== null;
  const baselineData = data.filter(
    (d: BarMetric) =>
      d.PerformerName === PerformerName.Baseline && (showAllModels || (!showAllModels && d.TopFlag === 'true'))
  );
  var group = baselineData[0]['Group'];
  var metric = baselineData[0]['Metric'];
  const {barData, maxValue, minValue} = getBarData(
    data,
    teamNames,
    getSystemColor,
    getPatternColor,
    getReverseMetric,
    isOverview,
    showAllModels,
    metric,
    group
  );

  const sortedBaselines = baselineData.sort((a: BarMetric, b: BarMetric) => {
    if (a.Value && b.Value && a.Value > b.Value) return 1;
    if (a.Value && b.Value && b.Value > a.Value) return -1;
    return 0;
  });
  const minBaseline = sortedBaselines[0];
  const maxBaseline = sortedBaselines[sortedBaselines.length - 1];
  const minY = minBaseline?.Value && minBaseline.Value < minValue ? minBaseline.Value : minValue;
  const maxY = maxBaseline?.Value && maxBaseline.Value > maxValue ? maxBaseline.Value : maxValue;
  const numOfBars = barData.reduce((acc, curr) => acc + curr.length, 0);
  const height = showAllModels
    ? numOfBars < 175
      ? 0 + barData.reduce((acc, curr) => acc + curr.length, 0) * 30
      : barData.reduce((acc, curr) => acc + curr.length, 0) * 30
    : barData.length <= 7
    ? 90 + barData.reduce((acc, curr) => acc + curr.length, 0) * 30
    : 300 + barData.reduce((acc, curr) => acc + curr.length, 0) * 30;

  const xdomain = barData.length * 2.5;
  const downloadBarChart = useRef(null as any);

  return barData.length > 0 ? (
    // <ChartCard showAllModels={showAllModels}>
    <ChartCard>
      <div style={{height: '30px', justifyContent: 'flex-end', display: 'flex', padding: '0.2rem'}}>
        <Tooltip title="Download as png">
          <Button
            shape="default"
            icon={<CameraOutlined />}
            onClick={() => {
              exportComponentAsPNG(downloadBarChart, {
                fileName: 'bar_chart.png',
                html2CanvasOptions: {
                  height: downloadBarChart.current.scrollHeight,
                  width: downloadBarChart.current.scrollWidth,
                },
              });
            }}
          />
        </Tooltip>
      </div>
      <svg width="0" height="0">
        <defs>
          {barData.map((bars: VictoryRow[], barIndex: number) =>
            bars.map(
              (datum: VictoryRow, rowIndex: number) =>
                datum.pattern && (
                  <pattern
                    key={`${barIndex}-${rowIndex}`}
                    id={`pattern-circles-${datum.x.replaceAll(/[\W]/gi, '')}`}
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    fill="black"
                    patternUnits="userSpaceOnUse"
                    patternContentUnits="userSpaceOnUse"
                  >
                    <rect id="pattern-background" width="4" height="4" fill={datum.fill} />
                    <circle id="pattern-circle" cx="1" cy="1" r="1" fill={datum.pattern} />
                  </pattern>
                )
            )
          )}
        </defs>
      </svg>
      <div style={{backgroundColor: 'white', maxHeight: '600px', overflowY: 'auto'}} ref={downloadBarChart}>
        <VictoryChart
          theme={chartTheme(
            400,
            height,
            20,
            showAllModels ? 0 : 55,
            showAllModels
              ? numOfBars < 175
                ? numOfBars >= 100
                  ? height + 340
                  : height + 90
                : height + 380
              : barData.length <= 7
              ? height + 20
              : height + 270
          )}
          padding={{
            bottom: showAllModels
              ? numOfBars <= 30
                ? 100
                : numOfBars <= 175
                ? numOfBars >= 100 && numOfBars <= 130
                  ? 260
                  : 80
                : 10
              : 10,
            left: 20,
            right: 15,
            top: showAllModels
              ? numOfBars <= 30
                ? -5
                : numOfBars <= 175
                ? numOfBars < 175
                  ? numOfBars === 100
                    ? 110
                    : 90
                  : 80
                : 50
              : barData.length <= 7
              ? 0
              : -60,
          }}
          domain={
            showAllModels
              ? {
                  x:
                    numOfBars <= 30
                      ? [0, numOfBars * 1.15]
                      : numOfBars < 175
                      ? numOfBars > 100
                        ? [0, numOfBars * 1.085]
                        : numOfBars === 100
                        ? [0, numOfBars * 1.11]
                        : [0, numOfBars * 1.03]
                      : [0, numOfBars * 1.062],
                  y: [minY, maxY],
                }
              : {
                  x: [0, barData.length <= 7 ? barData.length * 2.5 : xdomain],
                  y: [minY, maxY],
                }
          }
          minDomain={
            showAllModels
              ? {
                  y: 0,
                  x:
                    numOfBars <= 30
                      ? -numOfBars * 0.01
                      : numOfBars < 175
                      ? numOfBars === 100
                        ? -numOfBars * 0.04
                        : -numOfBars * 0.03
                      : -numOfBars * 0.07,
                }
              : {y: 0, x: barData.length <= 7 ? -3.8 : -12}
          }
          domainPadding={
            showAllModels ? {x: numOfBars <= 30 ? 0.8 : numOfBars < 175 ? -0.8 : -0.8} : {x: numOfBars * 1.5}
          }
          containerComponent={<VictoryVoronoiContainer labels={({datum}) => `y: ${datum.x}`} />}
        >
          <VictoryAxis dependentAxis />

          <VictoryGroup offset={showAllModels ? 28 : 22} horizontal>
            {barData.map((data, index) => (
              <VictoryBar
                key={index}
                domainPadding={{x: 0}}
                data={data}
                style={{
                  data: {
                    fill: ({datum}) =>
                      datum.pattern ? `url(#pattern-circles-${datum.x.replaceAll(/[\W]/gi, '')})` : datum.fill,
                  },
                  labels: {
                    fill: theme.colors.textPrimary,
                  },
                }}
                barWidth={({datum}) =>
                  datum.x.includes('LUAR') || datum.x.includes('SBERT')
                    ? showAllModels
                      ? numOfBars <= 30
                        ? 23
                        : numOfBars <= 40
                        ? 24
                        : 29
                      : 20
                    : showAllModels
                    ? numOfBars <= 30
                      ? 23
                      : 28
                    : 28
                }
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            target: 'labels',
                            mutation: (props) => {
                              return props.text === props.text + '   ' + props.datum.y.toString()
                                ? null
                                : {text: props.text + '   ' + props.datum.y.toString()};
                            },
                          },
                        ];
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: 'data',
                            mutation: () => {},
                          },
                          {
                            target: 'labels',
                            mutation: () => ({active: true}),
                          },
                        ];
                      },
                    },
                  },
                  {
                    target: 'labels',
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            target: 'labels',
                            mutation: (props) => {
                              return props.text === props.text + '   ' + props.datum.y.toString()
                                ? null
                                : {text: props.text + '   ' + props.datum.y.toString()};
                            },
                          },
                        ];
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: 'labels',
                            mutation: () => {},
                          },
                          {
                            target: 'labels',
                            mutation: () => ({active: true}),
                          },
                        ];
                      },
                    },
                  },
                ]}
                labelComponent={
                  // <VictoryTooltip/>
                  <VictoryLabel
                    textAnchor={maxY <= 0 ? 'end' : 'start'}
                    x={minY === 0 ? 31 : maxY <= 0 ? 369 : (-minY / (maxY - minY)) * 400 + 5}
                    dx={0}
                    dy={-2}
                    backgroundComponent={<Rect />}
                    backgroundPadding={{
                      left: maxY <= 0 ? 30 : 2,
                      right: maxY <= 0 ? 2 : 65, //30
                      top: 2,
                      bottom: 3,
                    }}
                    backgroundStyle={{
                      fill: theme.colors.neutral0,
                      opacity: ({datum}) => (datum.label ? 0.8 : 0),
                    }}
                  />
                }
              />
            ))}
          </VictoryGroup>
        </VictoryChart>
      </div>
    </ChartCard>
  ) : (
    <div />
  );
};

export const getBarData = (
  data: BarMetric[],
  teamNames: Record<string, string>,
  getSystemColor: (performerName: string, systemName: string) => string,
  getPatternColor: (performerName: string, systemName: string) => string,
  getReverseMetric: (metric: string, group: string) => number,
  isOverview: boolean,
  showAll?: boolean,
  metric?: any,
  group?: any
) => {
  const barData: VictoryRow[] = [];
  let maxValue = 0;
  let minValue = 0;
  for (let d of data) {
    if (
      d.Value !== null &&
      // d.PerformerName !== PerformerName.Baseline &&
      (showAll || (!showAll && d.TopFlag === 'true'))
    ) {
      const teamName = teamNames[d.PerformerName];
      const theme = performerTheme[d.PerformerName as PerformerName];
      let fillColor = theme?.primary;
      let patternColor = theme?.text;
      if (showAll || isOverview) {
        fillColor = getSystemColor(d.PerformerName, d.System);
        patternColor = getPatternColor(d.PerformerName, d.System);
      }
      const row = {
        x: isOverview
          ? showAll
            ? `${d.Genre} ${d.System}`
            : `${d.Genre} ${d.System}`
          : showAll
          ? `${teamName} ${d.System}`
          : teamName,
        y: d.Value, //* getReverseMetric(d.Metric, d.Group),
        y0: 0,
        x0: 0,
        label: isOverview
          ? showAll
            ? `${d.Genre} ${d.System}`
            : `${d.Genre} ${d.System}`
          : showAll
          ? `${teamName} ${d.System}`
          : `${teamName} ${d.System}`,
        fill: fillColor,
        pattern: d.System.includes(FINETUNED) ? patternColor : null,
      };
      barData.push(row);
      if (row.y > maxValue) {
        maxValue = row.y;
      }
      if (row.y < minValue) {
        minValue = row.y;
      }
    }
  }
  const sortedData = barData.sort((a: VictoryRow, b: VictoryRow) => {
    var revMetric = metric === undefined && group === undefined ? 1 : getReverseMetric(metric, group);
    if (a.y > b.y) {
      if (revMetric === -1) {
        return -1;
      }
      return 1;
    }

    if (b.y > a.y) {
      if (revMetric === -1) {
        return 1;
      }
      return -1;
    }

    return 0;
  });

  const genresMap: Record<string, VictoryRow[]> = {};

  sortedData.forEach((entry) => {
    const genre = entry.x.split(' ')[0]; // Extracting the genre part
    if (!genresMap[genre]) {
      genresMap[genre] = []; // Initialize the genre array if it doesn't exist
    }
    genresMap[genre].push(entry);
  });
  var groupedData: {[key: string]: VictoryRow[]} = {};
  barData.forEach((obj) => {
    const firstHalfX = obj.x.includes('sample') ? obj.x.split(')')[0] + ')' : obj.x.split(' ')[0]; // Get the first half of 'x' value
    if (!groupedData[firstHalfX]) {
      groupedData[firstHalfX] = [];
    }
    groupedData[firstHalfX].push(obj);
  });

  const arrayOfArrays: VictoryRow[][] = Object.entries(groupedData).map(([_key, value]) => value);
  // Function to find the maximum value in an array while excluding 'LUAR' or 'SBERT'
  const findMaxValue = (arr: VictoryRow[]): number => {
    const filteredArr = arr.filter((obj) => !obj.x.includes('LUAR') && !obj.x.includes('SBERT'));
    return Math.max(...filteredArr.map((obj) => obj.y));
  };

  // Sorting the outer arrays based on the maximum/minimum value
  var revMetric = metric === undefined && group === undefined ? 1 : getReverseMetric(metric, group);
  if (revMetric === 1) {
    arrayOfArrays.sort((a, b) => findMaxValue(a) - findMaxValue(b));
  } else {
    arrayOfArrays.sort((a, b) => findMaxValue(b) - findMaxValue(a));
  }

  // Function to sort the inner arrays based on 'x' value containing 'LUAR' or 'SBERT'
  if (isOverview) {
    const sortArrays = (arrays: VictoryRow[][]) => {
      arrays.forEach((innerArray) => {
        innerArray.sort((a, b) => {
          const hasLUARorSBERT = (str: string) =>
            str.includes('LUAR') || str.includes('SBERT') || str.includes('BASELINE');
          const aHasPriority = hasLUARorSBERT(a.x);
          const bHasPriority = hasLUARorSBERT(b.x);

          if (aHasPriority && !bHasPriority) {
            return 1; // 'a' has priority, move it down
          } else if (!aHasPriority && bHasPriority) {
            return -1; // 'b' has priority, keep 'a' above 'b'
          } else {
            return 0; // Maintain the order for elements without 'LUAR' or 'SBERT'
          }
        });
      });
    };
    // Sorting the inner arrays
    sortArrays(arrayOfArrays);
  } else {
    const putLUARAtBottom = (lists: VictoryRow[][]) => {
      lists.sort((a, b) => {
        const labelA = a[0].x;
        const labelB = b[0].x;
        if (labelA.includes('BASELINE') && !labelB.includes('BASELINE')) {
          return 1; // 'LUAR' should be at the bottom
        } else if (!labelA.includes('BASELINE') && labelB.includes('BASELINE')) {
          return -1; // 'LUAR' comes after other elements
        } else {
          return 0; // Maintain the order
        }
      });
    };

    // Sorting the inner arrays
    putLUARAtBottom(arrayOfArrays);
  }

  return {barData: arrayOfArrays, maxValue, minValue};
};

const ChartCard = styled.div<any>`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  width: 100%;
  /* max-height:600px;
  overflow-y:auto; */
  display: ${(props: any) => props.showAllModels && 'flex'};
`;

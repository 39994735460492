import styled from '@emotion/styled';
import {Checkbox, Modal} from 'antd';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import React, {useState} from 'react';
import {MetricsSummary, TaskAreaNames} from '../../definitions/metrics';
import {useDataContext} from '../../context/DataContext';

export type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  visibleMetrics: Record<string, boolean>;
  setVisibleMetrics: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
};

export const MetricsSettingsModal: React.FC<Props> = ({showModal, setShowModal, visibleMetrics, setVisibleMetrics}) => {
  const [{metricsSummary}] = useDataContext();
  const [tempVisibleMetrics, setTempVisibleMetrics] = useState<Record<string, boolean>>(visibleMetrics);

  const visibilityChanged = (e: CheckboxChangeEvent, metric: string) => {
    setTempVisibleMetrics((prevVisibleMetrics) => {
      return {
        ...prevVisibleMetrics,
        [metric]: e.target.checked,
      };
    });
  };

  const handleOk = () => {
    setVisibleMetrics(tempVisibleMetrics);
    setShowModal(false);
  };

  const handleCancel = () => {
    // cleanup
    setTempVisibleMetrics(visibleMetrics);
    setShowModal(false);
  };
  return (
    <Modal title="Metrics Grid Settings" open={showModal} onOk={handleOk} onCancel={handleCancel}>
      {metricsSummary?.map((metricsGroup: MetricsSummary, index: number) => (
        <React.Fragment key={`${metricsGroup.Group}-visibilityGroup`}>
          <TaskAreaTitle index={index}>{TaskAreaNames[metricsGroup.Group as keyof typeof TaskAreaNames]}</TaskAreaTitle>
          <CheckboxGrid>
            {metricsGroup.MetricsList.map((metric: string) =>
              metric.includes('[') ? (
                <StyledCheckbox
                  style={{display: 'none'}}
                  checked={tempVisibleMetrics[`${metricsGroup.Group}|${metric}`]}
                  onChange={(e: CheckboxChangeEvent) => visibilityChanged(e, `${metricsGroup.Group}|${metric}`)}
                  key={`${metricsGroup.Group}|${metric}-visibilityCheckbox`}
                >
                  {metric}
                </StyledCheckbox>
              ) : (
                <StyledCheckbox
                  checked={tempVisibleMetrics[`${metricsGroup.Group}|${metric}`]}
                  onChange={(e: CheckboxChangeEvent) => visibilityChanged(e, `${metricsGroup.Group}|${metric}`)}
                  key={`${metricsGroup.Group}|${metric}-visibilityCheckbox`}
                >
                  {metric}
                </StyledCheckbox>
              )
            )}
          </CheckboxGrid>
        </React.Fragment>
      ))}
    </Modal>
  );
};

const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 4px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 8px;
  font-size: 10px;
`;

const TaskAreaTitle = styled.div<any>`
  margin: ${(props: any) => (props.index === 0 ? `0px 8px 4px 8px` : `16px 8px 4px 8px`)};
  font-size: 12px;
  font-family: Archivo;
`;

import {theme} from './emotionTheme';

export const chartTheme = (width = 400, height = 250, padding = 50, tickLabelPadding = 8) => {
  return {
    axis: {
      style: {
        axis: {
          fill: 'transparent',
          stroke: theme.colors.textPrimary,
          strokeWidth: 0,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        },
        axisLabel: {
          textAnchor: 'middle',
          fontFamily: "Space Mono', 'Helvetica Neue', Helvetica, sans-serif",
          fontSize: 12,
          letterSpacing: 'normal',
          padding: 8,
          fill: theme.colors.textPrimary,
          stroke: 'transparent',
          strokeWidth: 0,
        },
        grid: {
          fill: 'none',
          stroke: 'none',
          strokeDasharray: '5, 5',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          pointerEvents: 'painted',
        },
        ticks: {
          fill: 'transparent',
          size: 5,
          stroke: 'none',
          strokeWidth: 1,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        },
        tickLabels: {
          fontFamily: "'Space Mono', 'Helvetica Neue', Helvetica, sans-serif",
          fontSize: 10,
          letterSpacing: 'normal',
          padding: tickLabelPadding,
          fill: theme.colors.textPrimary,
          stroke: 'transparent',
          strokeWidth: 0,
        },
      },
      width,
      height,
      padding,
    },
    bar: {
      style: {
        data: {fill: '#455A64', padding: 8, strokeWidth: 0},
        labels: {
          fontFamily: "'Space Mono', 'Helvetica Neue', Helvetica, sans-serif",
          fontSize: 10,
          letterSpacing: 'normal',
          padding: 8,
          fill: theme.colors.textPrimary,
          stroke: 'transparent',
          strokeWidth: 0,
        },
      },
      width,
      height,
      padding,
    },
    line: {
      style: {
        data: {
          stroke: theme.colors.neutral80,
          strokeWidth: '1px',
          strokeDasharray: '5, 5',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        },
        labels: {
          fontFamily: "'Space Mono', 'Helvetica Neue', Helvetica, sans-serif",
          fontSize: 10,
          letterSpacing: 'normal',
          padding: 8,
          fill: theme.colors.textPrimary,
          stroke: 'transparent',
          strokeWidth: 0,
        },
      },
    },
    chart: {width, height, padding},
  };
};

/** @jsxImportSource @emotion/react */
/* eslint no-eval: 0 */
import styled from '@emotion/styled';
import {Spin} from 'antd';
import {FINETUNED, PerformerName} from '../../definitions/metrics';
import {useState, useMemo} from 'react';
import {TaskAreaNames, TA1tradeOffAvailableMetics} from '../../definitions/metrics';
import {DropdownMenuItem} from '../DropdownMenu';
import Plot from 'react-plotly.js';
import {useMatch} from 'react-router-dom';
import {useAuthContext} from '../../context/AuthContext';
import {useDataContext} from '../../context/DataContext';
import {PageRoutes} from '../../definitions/routes';
import {Center} from '../../styles/common';
import {exportComponentAsPNG} from 'react-component-export-image';
import {CameraOutlined} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';
import React, {useRef} from 'react';

export interface LineMetric {
  x: number[];
  y: number[];
  x_tick_locations: number[];
  y_tick_locations: number[];
  x_tick_labels: string[];
  y_tick_labels: string[];
  x_limits: number[];
  y_limits: number[];
  x_hover: number[];
  y_hover: number[];
  t_eer: number;
  legend: string;
  performer: string;
  linecolor: string;
  subplotAxisIndex: number;
  ta: string;
  metricName: string;
  genre: string;
  metricDict: {[id: string]: {value: Number; isBest: boolean}};
  ta1metricScatterValue: number[];
  system: string;
  group: string;
}

export interface PlotData {
  x: number[];
  y: number[];
}

export interface CoordDataTA2 {
  x_tick_locations: number[];
  y_tick_locations: number[];
  x_tick_labels: string[];
  y_tick_labels: string[];
  x_limits: number[];
  y_limits: number[];
  x_hover: number[];
  y_hover: number[];
}
export interface ModelsChartProps {
  teamNames: Record<string, string>;
  showAllModels: boolean;
  overlayDet: boolean;
  taskArea: string;
  metricVal: Record<TaskAreaNames, DropdownMenuItem>;
}

export const LineChart: React.FC<ModelsChartProps> = ({teamNames, showAllModels, overlayDet, taskArea, metricVal}) => {
  const performerNames: string[] = useMemo(() => {
    const names = Object.values(PerformerName);
    return names.filter((name) => name !== 'Baseline');
  }, []);

  const [loading, setLoading] = useState(true);
  const [lineChartData, setLineChartData] = useState<LineMetric[]>([]);
  const [topFlagData, setTopFlagData] = useState<{}>({});

  const [{selectedTeam}] = useAuthContext();
  const [{performerValues, performerComparisonValues, selectedGenre}, {getSystemColor}] = useDataContext();

  const selectedPerformerName = Object.keys(teamNames).find((key) => teamNames[key] === selectedTeam)!;
  const match = useMatch({path: PageRoutes.PERFORMEROVERVIEW});
  const isOverview = match !== null;

  const ta1AvailableMetrics: string[] = Object.values(TA1tradeOffAvailableMetics);

  const result: any | null = useMemo(() => {
    return isOverview ? performerValues : performerComparisonValues;
  }, [performerValues, isOverview, performerComparisonValues]);

  useMemo(() => {
    var result_line: LineMetric[] = [];
    if (result !== null && result !== undefined && Object.keys(result).includes('absoluteValues')) {
      var topFlagMapping: Record<string, string> = {};
      for (var key in result['absoluteValues']) {
        let metricObj = result['absoluteValues'][key];
        for (var obj in metricObj) {
          let listObj = metricObj[obj];
          for (var lObj in listObj) {
            let finalKey =
              key +
              '|' +
              listObj[lObj]['Group'] +
              '|' +
              listObj[lObj]['Metric'] +
              '|' +
              listObj[lObj]['PerformerName'] +
              '|' +
              listObj[lObj]['System'];
            topFlagMapping[finalKey] = listObj[lObj]['TopFlag'];
          }
        }
      }
      setTopFlagData(topFlagMapping);
    }

    if (result !== null && result !== undefined && Object.keys(result).includes('errorTradeoffs')) {
      for (var resLine of result['errorTradeoffs']) {
        result_line.push({
          x: resLine['x_values'],
          y: resLine['y_values'],
          x_tick_locations: resLine['Group'] === 'TA1' ? [] : JSON.parse(resLine['x_tick_locations']),
          y_tick_locations: resLine['Group'] === 'TA1' ? [] : JSON.parse(resLine['y_tick_locations']),
          x_tick_labels: resLine['Group'] === 'TA1' ? [] : eval(resLine['x_tick_labels']),
          y_tick_labels: resLine['Group'] === 'TA1' ? [] : eval(resLine['y_tick_labels']),
          x_limits: resLine['Group'] === 'TA1' ? [] : JSON.parse(resLine['x_limits']),
          y_limits: resLine['Group'] === 'TA1' ? [] : JSON.parse(resLine['y_limits']),
          x_hover: resLine['Group'] === 'TA1' ? [] : JSON.parse(resLine['x_hover']),
          y_hover: resLine['Group'] === 'TA1' ? [] : JSON.parse(resLine['y_hover']),
          t_eer: resLine['Group'] === 'TA1' ? 0 : resLine['transformed_EER'],
          subplotAxisIndex: Number(performerNames.indexOf(resLine['PerformerName']) + 1),
          legend: resLine['legend'],
          performer: resLine['PerformerName'],
          linecolor: getSystemColor(resLine['PerformerName'], resLine['System'])!,
          metricName: resLine['Metric'],
          metricDict: resLine['MetricValue'],
          genre: resLine['Genre'],
          ta: TaskAreaNames[resLine['Group'] as keyof typeof TaskAreaNames],
          ta1metricScatterValue:
            resLine['Group'] === 'TA1' ? [resLine['MetricValue_at1'], resLine['MetricValue_at8']] : [-1, -1],
          system: resLine['System'],
          group: resLine['Group'],
        });
      }
      setLineChartData(result_line);
    }
  }, [result, getSystemColor, performerNames]);

  const metricValue = metricVal[taskArea as keyof typeof metricVal].label; //'Average Precision at 8';
  const [lineDataPerformer, setLineDataPerformer] = useState<LineMetric[]>(
    getLineData(
      lineChartData,
      metricValue,
      taskArea,
      isOverview,
      selectedPerformerName,
      topFlagData,
      selectedGenre,
      showAllModels
    )
  );

  useMemo(() => {
    setLineDataPerformer(
      getLineData(
        lineChartData,
        metricValue,
        taskArea,
        isOverview,
        selectedPerformerName,
        topFlagData,
        selectedGenre,
        showAllModels
      )
    );
    setLoading(false);
  }, [
    taskArea,
    showAllModels,
    metricValue,
    lineChartData,
    isOverview,
    selectedPerformerName,
    selectedGenre,
    topFlagData,
  ]);

  const initialCoordDataTA2: CoordDataTA2 = {
    x_tick_locations: [],
    y_tick_locations: [],
    x_tick_labels: [],
    y_tick_labels: [],
    x_limits: [],
    y_limits: [],
    x_hover: [],
    y_hover: [],
  };

  const ta2Data = lineChartData.find((d: LineMetric) => d.ta === TaskAreaNames.TA2) || {
    x_tick_locations: [],
    y_tick_locations: [],
    x_tick_labels: [],
    y_tick_labels: [],
    x_limits: [],
    y_limits: [],
    x_hover: [],
    y_hover: [],
  };

  const [coordTickLocationsTa2, setCoordTickLocationsTa2] = useState<CoordDataTA2>(initialCoordDataTA2);

  useMemo(() => {
    setCoordTickLocationsTa2({
      x_tick_locations: ta2Data.x_tick_locations,
      y_tick_locations: ta2Data.y_tick_locations,
      x_tick_labels: ta2Data.x_tick_labels,
      y_tick_labels: ta2Data.y_tick_labels,
      x_limits: ta2Data.x_limits,
      y_limits: ta2Data.y_limits,
      x_hover: ta2Data.x_hover,
      y_hover: ta2Data.y_hover,
    });
  }, [lineChartData]);

  var overallLineData = lineDataPerformer.filter((data) => data.ta === taskArea);
  var genreList = Array.from(new Set(overallLineData.map((d: LineMetric) => d.genre)));
  var plotLine: PlotData[] = getPlotData(
    overallLineData,
    'lines',
    metricValue,
    taskArea,
    overlayDet,
    ta1AvailableMetrics,
    isOverview
  );
  var plotScatter = getPlotData(
    overallLineData,
    'markers',
    metricValue,
    taskArea,
    overlayDet,
    ta1AvailableMetrics,
    isOverview
  );

  const data = plotLine.concat(plotScatter);

  var yaixsTitle = ta1AvailableMetrics.includes(metricValue)
    ? metricValue.replace(/1/g, 'k').replace(/8/g, 'k')
    : metricValue;
  var noViz = !ta1AvailableMetrics.includes(metricValue) && taskArea === TaskAreaNames.TA1 ? true : false;

  const downloadLineChart = useRef(null as any);
  const downloadLineChartGrid = useRef(null as any);
  return noViz ? (
    <ChartCard
      style={{maxHeight: '5%', minHeight: '5%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
      <div style={{textAlign: 'center', maxHeight: '5%', minHeight: '5%'}}>
        No metric relevant data for this visualization.
      </div>
    </ChartCard>
  ) : taskArea !== TaskAreaNames.TA3 ? (
    overlayDet ? (
      <ChartCard>
        <div style={{float: 'right', padding: '0.2rem'}}>
          <Tooltip title="Download as png">
            <Button
              shape="default"
              icon={<CameraOutlined />}
              onClick={() => {
                exportComponentAsPNG(downloadLineChart, {
                  fileName: 'line_chart.png',
                  html2CanvasOptions: {
                    height: downloadLineChart.current.scrollHeight,
                    width: downloadLineChart.current.scrollWidth,
                  },
                });
              }}
            />
          </Tooltip>
        </div>
        <div ref={downloadLineChart}>
          {!loading ? (
            <Plot
              data={data}
              style={{display: 'flex'}}
              layout={{
                autosize: true,
                grid: {rows: 1, columns: 1, pattern: 'independent'},
                height: 450,
                showlegend: false,
                xaxis:
                  taskArea === TaskAreaNames.TA1
                    ? {
                        title: {
                          text: 'Top k',
                          standoff: 5,
                        },
                        position: 0,
                        type: 'log',
                        rangemode: 'tozero',
                        autorange: true,
                      }
                    : {
                        title: {
                          text: 'False Positive Rate',
                          standoff: 5,
                        },
                        position: 0,
                        tickmode: 'array',
                        tickvals: coordTickLocationsTa2.x_tick_locations,
                        ticktext: coordTickLocationsTa2.x_tick_labels,
                        range: coordTickLocationsTa2.x_limits,
                        zeroline: false,
                        linewidth: 1,
                      },
                yaxis:
                  taskArea === TaskAreaNames.TA1
                    ? {
                        title: yaixsTitle,
                        rangemode: 'nonnegative',
                        autorange: true,
                        linewidth: 1,
                      }
                    : {
                        title: {
                          text: 'False Negative Rate',
                          standoff: 5,
                        },
                        tickmode: 'array',
                        tickvals: coordTickLocationsTa2.y_tick_locations,
                        ticktext: coordTickLocationsTa2.y_tick_labels,
                        range: coordTickLocationsTa2.y_limits,
                        zeroline: false,
                        linewidth: 1,
                      },
                dragmode: false,
                margin: {
                  l: 56,
                  r: 0,
                  b: 50,
                  t: 30,
                },
              }}
              config={{
                displayModeBar: false,
                modeBarButtonsToRemove: [
                  'pan2d',
                  'select2d',
                  'lasso2d',
                  'resetScale2d',
                  'zoomOut2d',
                  'zoomIn2d',
                  'zoom2d',
                  'autoScale2d',
                ],
                responsive: true,
              }}
            />
          ) : (
            <Center>
              <Spin size="small"></Spin>
            </Center>
          )}
        </div>
      </ChartCard>
    ) : (
      <ChartCard>
        <div style={{float: 'right', padding: '0.2rem'}}>
          <Tooltip title="Download as png">
            <Button
              shape="default"
              icon={<CameraOutlined />}
              onClick={() => {
                exportComponentAsPNG(downloadLineChartGrid, {
                  fileName: 'line_chart_individual.png',
                  html2CanvasOptions: {
                    height: downloadLineChartGrid.current.scrollHeight,
                    width: downloadLineChartGrid.current.scrollWidth,
                  },
                });
              }}
            />
          </Tooltip>
        </div>
        <GridPlot
          ref={downloadLineChartGrid}
          style={{
            gridTemplateColumns: genreList.length > 2 || !isOverview ? '33.33% 33.33% 33.33%' : '100%',
          }}
        >
          {getSubplots(
            overallLineData,
            metricValue,
            performerNames,
            taskArea,
            yaixsTitle,
            genreList,
            !isOverview,
            ta1AvailableMetrics,
            coordTickLocationsTa2.x_hover,
            coordTickLocationsTa2.y_hover,
            coordTickLocationsTa2
          )}
        </GridPlot>
      </ChartCard>
    )
  ) : (
    <ChartCard
      style={{maxHeight: '5%', minHeight: '5%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
      <div style={{textAlign: 'center', maxHeight: '5%', minHeight: '5%'}}>
        No metric relevant data for this visualization.
      </div>
    </ChartCard>
  );
};

export const getLineData = (
  data: LineMetric[],
  metricValue: string,
  taskArea: string,
  isOverview: boolean,
  selectedPerformerName: string,
  topFlagData: Record<string, string>,
  selectedGenre: string | null,
  showAll?: boolean
) => {
  var filtered_data = data.filter(
    (d: LineMetric) => d.ta === taskArea && Object.keys(d.metricDict).includes(metricValue)
  );

  if (isOverview && selectedPerformerName !== undefined) {
    var filtered_data_genre = filtered_data.filter((d: LineMetric) => d.performer === selectedPerformerName);
    var genreList = Array.from(new Set(filtered_data_genre.map((obj) => obj['genre'])));
    filtered_data = filtered_data.filter((d: LineMetric) => genreList.includes(d.genre));
  } else if (!isOverview && selectedGenre !== undefined) {
    filtered_data = filtered_data.filter((d: LineMetric) => d.genre === selectedGenre);
  }

  if (!showAll) {
    filtered_data = filtered_data.filter((d: LineMetric) => {
      return topFlagData[d.genre + '|' + d.group + '|' + metricValue + '|' + d.performer + '|' + d.system] === 'true';
      // return d.metricDict[metricValue]['isBest'] === true
    });
  }

  return filtered_data;
};

export const getPlotData = (
  lineData: LineMetric[],
  mode: string,
  metricValue: string,
  taskArea: string,
  overlay: boolean,
  ta1AvailableMetrics: string[],
  isOverview: boolean
) => {
  if (taskArea === TaskAreaNames.TA1) {
    var filterParam: string = ta1AvailableMetrics.includes(metricValue)
      ? metricValue.replace(/1/g, 'k').replace(/8/g, 'k')
      : 'none';
    var lineMetric: LineMetric[] = [];
    lineData = filterParam === 'none' ? lineMetric : lineData.filter((data) => data.metricName === filterParam);
  }

  var result = lineData.map((data) => {
    var ta1ScatterMetric: number[][] = metricValue.includes('at 1')
      ? [
          [1, data.ta1metricScatterValue[0]],
          [8, data.ta1metricScatterValue[1]],
        ]
      : metricValue.includes('at 8')
      ? [
          [1, data.ta1metricScatterValue[0]],
          [8, data.ta1metricScatterValue[1]],
        ]
      : [];
    const meta = [];
    const x_d = taskArea === TaskAreaNames.TA1 ? data.x : data.x_hover;
    const y_d = taskArea === TaskAreaNames.TA1 ? data.y : data.y_hover;
    for (let i = 0; i < x_d.length; i++) {
      meta.push([x_d[i], y_d[i]]);
    }
    return {
      x:
        mode === 'markers'
          ? metricValue === 'Equal Error Rate' && taskArea === TaskAreaNames.TA2
            ? [Number(data.t_eer)]
            : taskArea === TaskAreaNames.TA1
            ? [ta1ScatterMetric[0][0], ta1ScatterMetric[1][0]]
            : []
          : data.x,
      y:
        mode === 'markers'
          ? metricValue === 'Equal Error Rate' && taskArea === TaskAreaNames.TA2
            ? [Number(data.t_eer)]
            : taskArea === TaskAreaNames.TA1
            ? [ta1ScatterMetric[0][1], ta1ScatterMetric[1][1]]
            : []
          : data.y,
      mode: mode,
      customdata:
        metricValue === 'Equal Error Rate' && taskArea === TaskAreaNames.TA2
          ? [data.metricDict['Equal Error Rate'].value]
          : ['no info'],
      title: data.genre,
      line: {
        dash: data.performer === 'Baseline' ? 'dash' : 'solid',
        width: 1,
      },
      text: isOverview
        ? data.performer === 'Baseline'
          ? Array(data.x.length).fill(data.performer + ' ' + data.genre + ' ' + data.system)
          : Array(data.x.length).fill(data.genre + ' ' + data.legend.split('|')[1])
        : Array(data.x.length).fill(data.performer + ' ' + data.legend.split('|')[1]),
      meta: taskArea === TaskAreaNames.TA1 ? data.x.map((d, index) => [d, data.y[index]]) : meta,
      hovertemplate:
        taskArea === TaskAreaNames.TA1
          ? '%{text}<br>%{xaxis.title.text}:%{x}<br>%{yaxis.title.text}:%{y}<extra></extra>'
          : metricValue === 'Equal Error Rate' && taskArea === TaskAreaNames.TA2 && mode === 'markers'
          ? '%{text}<br>%{xaxis.title.text}:%{customdata}<br>%{yaxis.title.text}:%{customdata}<extra></extra>'
          : '%{text}<br>%{xaxis.title.text}:%{meta[0]}<br>%{yaxis.title.text}:%{meta[1]}<extra></extra>',
      marker: {
        color: data.linecolor,
        size: 7,
        symbol:
          data.performer === PerformerName.Baseline
            ? 'triangle-down'
            : data.system.includes(FINETUNED)
            ? 'square'
            : 'circle',
      },
      hoverlabel: {font: {size: overlay ? 10 : 7}},
      hovermode: 'closest',
    };
  });
  return result;
};

export const getPlotLayout = (taskArea: string) => {};

export const getSubplotDataBaseline = (
  lineData: LineMetric[],
  mode: string,
  metricValue: string,
  performerNames: string[],
  taskArea: string
) => {
  var result: any[] = [];

  for (var i in performerNames) {
    let idAxis = i;
    let tmp = lineData.map((data) => {
      return {
        x:
          mode === 'markers'
            ? metricValue === 'Equal Error Rate' && taskArea === TaskAreaNames.TA2
              ? [data.x[0], Number(data.metricDict[metricValue]), data.x[data.x.length - 1]]
              : [data.x[0], data.x[data.x.length - 1]]
            : data.x,
        y:
          mode === 'markers'
            ? metricValue === 'Equal Error Rate' && taskArea === TaskAreaNames.TA1
              ? [data.y[0], Number(data.metricDict[metricValue]), data.y[data.y.length - 1]]
              : [data.y[0], data.y[data.y.length - 1]]
            : data.y,
        type: 'scatter',
        xaxis: 'x' + String(Number(idAxis) + 1),
        yaxis: 'y' + String(Number(idAxis) + 1),
        title: data.genre,
        mode: mode,
        line: {
          dash: data.performer === 'Baseline' ? 'dash' : 'solid',
          width: 1,
        },
        text: Array(data.x.length).fill(data.genre + ' ' + data.legend.split('|')[1]),
        hovertemplate: '<b>%{text}</b><br><br><extra></extra>',
        marker: {color: data.linecolor, size: 7},
      };
    });

    result = result.concat(tmp);
  }
  return result;
};

export const getSubplots = (
  lineData: LineMetric[],
  metricValue: string,
  performerNames: string[],
  taskArea: string,
  yaixsTitle: string,
  genreList: string[],
  isOverview: boolean,
  ta1AvailableMetrics: string[],
  x_hover: number[],
  y_hover: number[],
  coordTickLocationsTa2: CoordDataTA2
) => {
  var plotData: any[] = [];
  var items = isOverview ? performerNames : genreList;
  for (let i in items) {
    let plotTitle = items[i].replace(/\(/g, '<br>(');
    let filteredData = lineData.filter((data) =>
      isOverview ? data.performer === performerNames[i] || data.performer === 'Baseline' : data.genre === genreList[i]
    );
    let subplotLineData = getPlotData(
      filteredData,
      'lines',
      metricValue,
      taskArea,
      false,
      ta1AvailableMetrics,
      isOverview
    );
    let subplotScatterData = getPlotData(
      filteredData,
      'markers',
      metricValue,
      taskArea,
      false,
      ta1AvailableMetrics,
      isOverview
    );
    const fontSize = 10;
    let plot = (
      <Plot
        data={subplotLineData.concat(subplotScatterData)}
        config={{
          responsive: true,
          displayModeBar: false,
        }}
        style={{display: 'flex'}}
        layout={{
          autosize: true,
          height: 220,
          showlegend: false,
          xaxis:
            taskArea === TaskAreaNames.TA1
              ? {
                  title: {
                    text: 'Top k',
                    standoff: 5,
                    font: {size: fontSize, family: 'Space Mono'},
                  },
                  position: 0,
                  type: 'log',
                  rangemode: 'tozero',
                  autorange: true,
                  tickfont: {size: fontSize - 2},
                }
              : {
                  title: {
                    text: 'FPR',
                    font: {size: fontSize},
                    standoff: 0,
                  },
                  automargin: true,
                  position: 0,
                  tickmode: 'array',
                  tickfont: {size: fontSize - 2},
                  tickvals: coordTickLocationsTa2.x_tick_locations,
                  ticktext: coordTickLocationsTa2.x_tick_labels,
                  range: coordTickLocationsTa2.x_limits,
                  zeroline: false,
                  linewidth: 1,
                },
          yaxis:
            taskArea === TaskAreaNames.TA1
              ? {
                  title: {
                    text: yaixsTitle,
                    font: {size: fontSize},
                  },
                  rangemode: 'nonnegative',
                  autorange: true,
                  tickfont: {size: fontSize - 2},
                  linewidth: 1,
                }
              : {
                  title: {
                    text: 'FNR',
                    font: {size: fontSize},
                    standoff: 0,
                  },
                  tickmode: 'array',
                  tickfont: {size: fontSize - 2},
                  tickvals: coordTickLocationsTa2.y_tick_locations,
                  ticktext: coordTickLocationsTa2.y_tick_labels,
                  range: coordTickLocationsTa2.y_limits,
                  zeroline: false,
                  linewidth: 1,
                },
          dragmode: false,
          margin: {
            l: 38,
            r: 20,
            b: 30,
            t: isOverview ? 10 : 35,
          },
          title: isOverview ? '' : plotTitle,
          titlefont: isOverview ? {size: 0} : {size: 8},
        }}
      />
    );
    plotData.push(<div>{plot}</div>);
  }
  return plotData;
};

const ChartCard = styled.div<any>`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  width: 100%;
  display: ${(props: any) => props.showAllModels && 'flex'};
  min-height: 52%;
  max-height: 52%;
  overflow-y: auto;
  margin-bottom: 5%;
  background-color: white;
`;

const GridPlot = styled.div<any>`
  display: grid;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  padding: 0px;
  text-align: center;
`;

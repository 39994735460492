export enum TaskAreaNames {
  TA1 = 'Feature Space Generation',
  TA2 = 'Text Attribution',
  TA3 = 'Privacy Preservation',
}

export type TaskArea = keyof typeof TaskAreaNames;

export enum TeamName {
  Alert = '1BBN (ALERT)',
  Author = 'CRA (AUTHOR)',
  Pausit = 'UPenn (PAUSIT)',
  Relax = 'Leidos (RELAX)',
  Sadiri = 'ISI (SADIRI)',
  Signature = 'SRI (SIGNATURE)',
  Baseline = 'BASELINE',
}

export enum PerformerName {
  Alert = 'BBN (ALERT)',
  Author = 'CRA (AUTHOR)',
  Pausit = 'UPenn (PAUSIT)',
  Relax = 'Leidos (RELAX)',
  Sadiri = 'ISI (SADIRI)',
  Signature = 'SRI (SIGNATURE)',
  Baseline = 'Baseline',
}

export enum PerformerFullNames {
  CharlesRiverAnalytics = 'Charles River Analytics',
  Leidos = 'Leidos',
  RaytheonBBN = 'Raytheon BBN',
  SRIInternational = 'SRI International',
  UniversityOfPenn = 'University of Pennsylvania',
  UniversityOfSC = 'University of Southern California',
  Baseline = 'Baseline',
}

export const FINETUNED = ' (finetuned)';

export interface MetricsSummary {
  DefaultDisplayMetrics: string[];
  Group: TaskArea;
  MetricsList: string[];
  PrimaryMetrics: string; // string is a single metric
  ReverseMetrics: Record<string, number>;
  TaskAreaNames: TaskAreaNames;
}

export interface PerformerSummary {
  GenreList: string[];
  PerformerFullNames: PerformerFullNames;
  PerformerName: PerformerName;
  PerformerShortNames: string;
  SystemCount: number;
  SystemList: string[];
  Team: TeamName;
}

export interface DataVersions {
  AllVersions: string[];
  DefaultVersion: string;
}

export interface ComparisonValues {
  absoluteValues: BarMetricsByGenre;
  improvementOverBaseline: GridMetricsByGenre;
  errorTradeoffs: ErrorTradeoff[];
}

export interface PerformerValues {
  absoluteValues: BarMetricsByGenre;
  improvementOverBaseline: GridMetric[];
  errorTradeoffs: ErrorTradeoff[];
}

export interface GridMetricsByGenre {
  [key: string]: GridMetric[];
}

export interface GridMetric {
  Group: string;
  Baseline_Value: number | null;
  Improvement: number | null;
  PerformerName: string;
  Value: number;
  Metric: string;
  Genre: string;
  System: string;
  RangeKey: string; //genre|group|metric
}
export interface GridMetricNested {
  Group: string;
  Baseline_Value: number | null;
  Improvement: number[];
  PerformerName: string;
  Value: number[];
  Metric: string;
  Genre: string;
  System: string;
  RangeKey: string; //genre|group|metric
}

export type GridProperty = keyof GridMetric;

export interface AllBarMetrics {
  [key: string]: BarMetric[]; // key is Group|Metric Name
}

export interface BarMetricsByGenre {
  [key: string]: AllBarMetrics;
}

export interface BarMetric {
  TopFlag: string;
  Group: string;
  datetime: string; // ISO string
  PerformerName: string;
  Value: number;
  Metric: string;
  Genre: string;
  System: string;
  RangeKey: string; //genre|group|metric|system
}

export interface ErrorTradeoff {
  Group: string;
  MetricValue_at1: string;
  MetricValue: {[key: string]: {value: number; isBest: boolean}};
  legend: string; // performerName|system
  MetricValue_at8: string;
  PerformerName: string;
  Metric: string;
  Genre: string;
  x_values: number[];
  y_values: number[];
  System: string;
  RangeKey: string; // genre|group|metric|system
}

export enum TA1tradeOffAvailableMetics {
  AverageSuccessAt1 = 'Average Success at 1',
  AverageSuccessAt8 = 'Average Success at 8',
  AverageRecallAt1 = 'Average Recall at 1',
  AverageRecallAt8 = 'Average Recall at 8',
  AveragePrecisionAt1 = 'Average Precision at 1',
  AveragePrecisionAt8 = 'Average Precision at 8',
}

/** @jsxImportSource @emotion/react */
import {useCallback, useEffect, useMemo, useState} from 'react';
import styled from '@emotion/styled';
import {MetricsBar, MetricsGrid, PerformerLegend, Submissions, Header, DefaultNavigation} from '../components';
import {Column, Content, Page, Row, Center} from '../styles/common';
import {Spin} from 'antd';
import {useAuthContext} from '../context/AuthContext';
import {UserRole} from '../definitions/auth';
import {useDataContext} from '../context/DataContext';
import {useMetricsSummary, usePerformerSummary, useVersions} from '../utils';
import {ErrorBoundary} from '../components/ErrorBoundary';
import React, {useRef} from 'react';
import {exportComponentAsPNG} from 'react-component-export-image';
import {useParams} from 'react-router-dom';
import {BadVersion} from './BadVersion';

export const PerformerComparison: React.FC<{}> = () => {
  const [{userGroup, authHeaders}] = useAuthContext();
  const [
    {
      metricsSummary,
      performerSummary,
      performerComparisonValues,
      performerTeams,
      selectedGenre,
      currentVersion,
      dataVersions,
    },
    {
      setMetricsSummary,
      setPerformerSummary,
      setPerformerComparisonValues,
      setCurrentVersion,
      setDataVersions,
      setSelectedGenre,
    },
  ] = useDataContext();
  const hasPerms = userGroup && userGroup.role === UserRole.Admin;
  const [fetchDataErrors, setFetchDataErrors] = useState<Record<string, any>>({});
  const params = useParams();
  const badVersion = params?.version && dataVersions?.AllVersions.indexOf(params.version) === -1;

  useEffect(() => {
    if (params?.version && dataVersions && dataVersions.AllVersions.indexOf(params.version) !== -1) {
      setCurrentVersion(params.version);
    }
  }, [params, setCurrentVersion, dataVersions]);

  useVersions(dataVersions, setDataVersions, currentVersion, setCurrentVersion, authHeaders, params);
  useMetricsSummary(metricsSummary, setMetricsSummary, setFetchDataErrors, authHeaders, currentVersion);
  usePerformerSummary(performerSummary, setPerformerSummary, setFetchDataErrors, authHeaders, currentVersion);
  useEffect(() => {
    if (!performerComparisonValues && currentVersion) {
      fetch(`${window.config.dashboardApiEndpoint}/${currentVersion}/values`, authHeaders)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(res.statusText);
        })
        .then((valuesData) => {
          setPerformerComparisonValues(valuesData);
          setSelectedGenre(Object.keys(valuesData?.absoluteValues)[0]);
        })
        .catch((error: any) => {
          setFetchDataErrors((prev) => ({
            ...prev,
            values: error,
          }));
        });
    }
  }, [currentVersion, performerComparisonValues, setPerformerComparisonValues, authHeaders]);

  const barData = useMemo(() => {
    const absoluteValues = performerComparisonValues?.absoluteValues;
    return absoluteValues && selectedGenre && absoluteValues[selectedGenre];
  }, [performerComparisonValues, selectedGenre]);

  const gridData = useMemo(() => {
    const improvementOverBaseline = performerComparisonValues?.improvementOverBaseline;
    return improvementOverBaseline && selectedGenre && improvementOverBaseline[selectedGenre];
  }, [performerComparisonValues, selectedGenre]);

  const noDataMessage = <p>Unable to load data</p>;
  const downloadPerformerComparison = useRef(null as any);
  const onScreenshotClick = useCallback(() => {
    exportComponentAsPNG(downloadPerformerComparison, {
      fileName: 'performer_comparison_view.png',
      html2CanvasOptions: {
        height: downloadPerformerComparison.current.scrollHeight,
        width: downloadPerformerComparison.current.scrollWidth + 0.01 * downloadPerformerComparison.current.scrollWidth,
      },
    });
  }, [downloadPerformerComparison]);

  return badVersion ? (
    <BadVersion />
  ) : hasPerms ? (
    <Page ref={downloadPerformerComparison}>
      <ErrorBoundary>
        <Header onScreenshotClick={onScreenshotClick} />
      </ErrorBoundary>
      <Content>
        <ErrorBoundary>
          {barData && metricsSummary ? (
            <ErrorBoundary>
              <MetricsBar data={barData} />
            </ErrorBoundary>
          ) : fetchDataErrors.values || fetchDataErrors.metricsSummary ? (
            <MetricDetailsContainer>
              <LineChartContainer>
                <Center>{noDataMessage}</Center>
              </LineChartContainer>
              <BarChartContainer>
                <Center>{noDataMessage}</Center>
              </BarChartContainer>
            </MetricDetailsContainer>
          ) : (
            <MetricDetailsContainer>
              <LineChartContainer>
                <Center>
                  <Spin size="small"></Spin>
                </Center>
              </LineChartContainer>
              <BarChartContainer>
                <Center>
                  <Spin size="small"></Spin>
                </Center>
              </BarChartContainer>
            </MetricDetailsContainer>
          )}
          <Column>
            {gridData && metricsSummary ? (
              <ErrorBoundary>
                <MetricsGrid data={gridData} columnLabels={performerTeams} columnProperty={'PerformerName'} />
              </ErrorBoundary>
            ) : fetchDataErrors.values || fetchDataErrors.metricsSummary ? (
              <CommonCardStyle>
                <Center>{noDataMessage}</Center>
              </CommonCardStyle>
            ) : (
              <CommonCardStyle>
                <Center>
                  <Spin size="large"></Spin>
                </Center>
              </CommonCardStyle>
            )}
            <Row>
              {Object.keys(performerTeams).length > 0 ? (
                <ErrorBoundary maxHeight={150}>
                  <PerformerLegend teamNames={performerTeams} />
                </ErrorBoundary>
              ) : fetchDataErrors.performerSummary ? (
                <LegendContainer>
                  <Center>{noDataMessage}</Center>
                </LegendContainer>
              ) : (
                <LegendContainer>
                  <Center>
                    <Spin size="small"></Spin>
                  </Center>
                </LegendContainer>
              )}
              {performerSummary ? (
                <ErrorBoundary maxHeight={150}>
                  <Submissions performerSummary={performerSummary} />
                </ErrorBoundary>
              ) : fetchDataErrors.performerSummary ? (
                <SubmissionContainer>
                  <Center>{noDataMessage}</Center>
                </SubmissionContainer>
              ) : (
                <SubmissionContainer>
                  <Center>
                    <Spin size="small"></Spin>
                  </Center>
                </SubmissionContainer>
              )}
            </Row>
          </Column>
        </ErrorBoundary>
      </Content>
    </Page>
  ) : (
    <DefaultNavigation userGroup={userGroup} />
  );
};

const CommonCardStyle = styled.div<any>`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  width: 100%;
  height: 86%;
  display: ${(props: any) => props.showAllModels && 'flex'};
  margin-bottom: 1%;
`;

const MetricDetailsContainer = styled.div<any>`
  height: 89%;
  width: 30%;
  border: none;
  box-shadow: none;
  margin-top: 1%;
  margin: 1%;
`;

const LineChartContainer = styled.div<any>`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  height: 55%;
`;

const BarChartContainer = styled.div<any>`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  height: 49%;
  margin-top: 2%;
`;

const SubmissionContainer = styled.div<any>`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  display: ${(props: any) => props.showAllModels && 'flex'};
  border-radius: 2px;
  width: 49%;
  min-height: 85px;
`;

const LegendContainer = styled.div<any>`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  display: ${(props: any) => props.showAllModels && 'flex'};
  border-radius: 2px;
  margin-right: 2%;
  width: 49%;
  min-height: 90px;
`;
